/* eslint-env browser */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Button from './button';
import { sendAnalytics, isTestEnvironment } from '../../util';
import { CloseButton } from './styled-components/buttonStyles';
import * as S from './styled-components/imageRotateModalStyles.js';

if (!isTestEnvironment()) {
  Modal.setAppElement('#___gatsby');
}

const ImageRotateModal = ({
  modalIsOpen,
  closeModal,
  title,
  src,
  srcSet,
  alt,
  isHorizontal,
  windowWidth,
  windowHeight,
}) => {
  const [rotate, setRotate] = useState(0);
  const [isHorizontalWindow, setisHorizontalWindow] = useState(false);
  const onRenderAnalytics = useCallback(
    () => {
      sendAnalytics(
        {
          event_category: 'Navigate',
          event_label: `View Rotate Image ${title}`,
          image_name: title,
        },
        'click'
      );
    },
    [title]
  );

  const onRotateClick = useCallback(
    () => {
      sendAnalytics(
        {
          event_category: 'Navigate',
          event_label: `Change Rotate Angle ${title} -${rotate}deg`,
          image_name: title,
        },
        'click'
      );
    },
    [title, rotate]
  );

  const handleRotate = () => {
    const newRotateValue = rotate === 270 ? 0 : rotate + 90;
    setRotate(newRotateValue);
    onRotateClick();
  };

  const setwindowOrientation = useCallback(
    () => {
      setisHorizontalWindow(1 > (windowHeight + 100) / windowWidth);
    },
    [setisHorizontalWindow, windowHeight, windowWidth]
  );

  const handleResize = useCallback(
    () => {
      setwindowOrientation();
    },
    [setwindowOrientation]
  );

  useEffect(
    () => {
      setwindowOrientation();
      window.addEventListener('resize', handleResize); // eslint-disable-line
      onRenderAnalytics();
    },
    [
      windowHeight,
      windowWidth,
      handleResize,
      setwindowOrientation,
      onRenderAnalytics,
    ]
  );

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel={title}
      className="modalContent"
      overlayClassName="modalOverlay"
    >
      <CloseButton onClick={closeModal}>
        <div>X</div>
      </CloseButton>

      <S.Title>{title}</S.Title>
      <S.ModalContentWrapper isHorizontalWindow={isHorizontalWindow}>
        <S.ImageContainer
          rotate={rotate}
          isHorizontalWindow={isHorizontalWindow}
        >
          <S.Image
            src={src}
            srcSet={srcSet}
            alt={alt}
            isHorizontal={isHorizontal}
            rotate={rotate}
          />
        </S.ImageContainer>
        <S.ButtonAndTextContainer>
          <Button
            text="click to rotate"
            clickHandler={handleRotate}
            mediumSize
            mediumColor
            marginTop={!isHorizontalWindow ? 0 : 5}
          />
          <S.RotationAmount show={rotate !== 0}>
            {`${rotate} degrees`}
          </S.RotationAmount>
          <S.EscNotice>esc key to close</S.EscNotice>
        </S.ButtonAndTextContainer>
      </S.ModalContentWrapper>
    </Modal>
  );
};

ImageRotateModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  src: PropTypes.string,
  srcSet: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  isHorizontal: PropTypes.bool,
  windowHeight: PropTypes.number,
  windowWidth: PropTypes.number,
};

export default ImageRotateModal;
