/* eslint-env browser */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BuyButton extends Component {
  constructor() {
    super();
    this.onAddToCart = this.onAddToCart.bind(this);
  }

  onAddToCart(title) {
    if (!window.gtag) {// eslint-disable-line
      return false;
    }
    typeof window !== 'undefined' &&
      window.gtag('event', 'click', {
        event_category: 'Navigate',
        event_label: `Add to cart ${title}`,
        image_name: title,
      });
  }

  render() {
    const { price, title, url, medium, width, height } = this.props.work;
    let { className, children } = this.props;
    className = `${className} snipcart-add-item`;
    const description = `${medium}, ${width}" x ${height}"`;

    return (
      <button
        href="#"
        className={className}
        data-item-name={title}
        data-item-price={price}
        data-item-id={url}
        data-item-url={`https://jmonehill.com/works/${url}`}
        data-item-description={description}
        data-item-max-quantity={1}
        onClick={() => this.onAddToCart(title)}
      >
        {children}
      </button>
    );
  }
}

BuyButton.propTypes = {
  work: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default BuyButton;
