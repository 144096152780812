import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BuyButton from './buyButton';
import * as S from './styled-components/buttonStyles';

class Button extends Component {
  render() {
    const {
      text,
      dark,
      gray,
      link,
      large,
      small,
      mediumSize,
      mediumColor,
      type,
      disabled,
      clickHandler,
      marginTop,
      buyButton,
      work,
    } = this.props;
    const buttonClass = classNames({
      darkButton: dark,
      mediumColor,
      grayButton: gray,
    });
    const buttonBoxClass = classNames({
      large: large,
      small: small,
      mediumSize
    });
    return (
      <S.ButtonBox
        className={buttonBoxClass}
        style={marginTop ? { marginTop: marginTop } : {}}
      >
        {link ? (
          <a href={link}>
            <button className={buttonClass} type={type} disabled={disabled}>
              <span>{text}</span>
            </button>
          </a>
        ) : !buyButton ? (
          <button
            className={buttonClass}
            type={type}
            disabled={disabled}
            onClick={clickHandler}
          >
            <span>{text}</span>
          </button>
        ) : (
          <BuyButton work={work} className={buttonClass}>
            <span>{text}</span>
          </BuyButton>
        )}
        <S.ButtonShadow className={buttonBoxClass} />
      </S.ButtonBox>
    );
  }
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  gray: PropTypes.bool,
  link: PropTypes.string,
  large: PropTypes.bool,
  small: PropTypes.bool,
  mediumSize: PropTypes.bool,
  mediumColor: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  clickHandler: PropTypes.func,
  marginTop: PropTypes.number,
  buyButton: PropTypes.bool,
  work: PropTypes.object,
};

export default Button;
